<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            required
            :state="inputStates('formData.branch')"
            error="Branch is required"
            label="Branch"
            description="Branch value for MOMS Notification creation"
          >
            <asom-input-select
              :state="inputStates('formData.branch')"
              v-model="formData.branch"
              :options="branchOptions"
            />
          </asom-form-field>
          <div></div>
          <asom-form-field
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            :state="inputStates('formData.isMaintainanceStaffInformed')"
            error="Please Inform Maintainance Staff"
            label=" "
          >
            <asom-input-checkbox
              v-model="formData.isMaintainanceStaffInformed"
              label="Is Maintainance Staff Informed"
              description="Check if Maintainance Staff is Informed"
            />
          </asom-form-field>
          <asom-icon icon="spinner" class="animate-spin" v-if="isLoadingFunctionalLocation" />
          <template v-if="formData.branch && !isLoadingFunctionalLocation">
            <asom-form-field
              required
              :state="inputStates('formData.functionalLocation')"
              error="Functional Location is required"
              label="Functional Location"
              description="Function Location value for MOMS Notification creation"
            >
              <asom-input-select
                :state="inputStates('formData.functionalLocation')"
                v-model="formData.functionalLocation"
                :options="functionalLocationOptions"
              />
            </asom-form-field>
            <div></div>
            <asom-form-field
              required
              :state="inputStates('formData.workCenter')"
              error="Work Center is required"
              label="Work Center"
              description="Work Center value for MOMS Notification creation"
            >
              <asom-input-select
                :object-model-value="false"
                :state="inputStates('formData.workCenter')"
                v-model="formData.workCenter"
                :options="workCenters"
              />
            </asom-form-field>
            <asom-form-field
              required
              :state="inputStates('formData.plannerGroup')"
              error="Planner Group is required"
              label="Planner Group"
              description="Planner Group value for MOMS Notification creation"
            >
              <asom-input-select
                :object-model-value="false"
                :state="inputStates('formData.plannerGroup')"
                v-model="formData.plannerGroup"
                :options="plannerGroups"
              />
            </asom-form-field>
          </template>
          <asom-form-field
            class="col-span-2"
            label="Supporting documents"
            :state="inputStates('formData.files')"
            error="Supporting documents are required"
          >
            <asom-upload
              v-model="formData.files"
              :category="attachmentCategories.FAULT_MANAGEMENT"
              :state="inputStates('formData.files')"
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createStationFaultClick"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createStationFault, GetMOMSFunctionalLocations, GetMOMSBranches, GetMOMSPlannerGroups, GetMOMSWorkCenters } from "@/services/faultManagement.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";
import { NSEWL } from "@/constants/values";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        description: null,
        isMaintainanceStaffInformed: false,
        files: [],
        branch: null,
        functionalLocation: null,
        workCenter: null,
        plannerGroup: null,
      },
      functionalLocationOptions: [],
      branchOptions: [],
      workCenters: [],
      plannerGroups: [],
      isLoadingFunctionalLocation: false,
    };
  },
  validations() {
    return {
      formData: {
        description: { required },
        branch: { required },
        functionalLocation: { required },
        workCenter: { required },
        plannerGroup: { required },
      },
    };
  },
  mounted() {
    this.loadBranches();
  },
  watch: {
    'formData.branch': function(newVal, oldVal) {
      if (!isEmpty(newVal)) {
        if (newVal != oldVal) {
          this.formData.functionalLocation = null;
          this.formData.workCenter = null;
          this.formData.plannerGroup = null;
        }
        this.loadWorkCenters()
        this.loadPlannerGroups();
        this.loadFunctionalLocations();
      }
    },
    'formData.functionalLocation': function(newVal) {
      if (!isEmpty(newVal)) {
        const workCenter = get(this.formData.functionalLocation, "workCenter");
        const plannerGroup = get(this.formData.functionalLocation, "planningGroup");
        if (!isEmpty(workCenter)) {
          this.formData.workCenter = workCenter;
        }
        if (!isEmpty(plannerGroup)) {
          this.formData.plannerGroup = plannerGroup;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      userLineId: "auth/userLineId",
      userLineName: "auth/userLineName"
    }),
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    get,
    createStationFaultClick() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async loadBranches() {
      const result = await GetMOMSBranches({ 
        lineId: this.userLineId,
      })
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.branchOptions = get(result, "payload.list", []);
      } else {
        this.error = result.payload;
      }
    },
    async loadWorkCenters() {
      const result = await GetMOMSWorkCenters({ 
        lineId: this.userLineId,
        branch: get(this.formData.branch, 'value', ''),
      })
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        var workCenterList = get(result, "payload.list");
        this.workCenters = (this.formData.branch.value === "SAFC" && this.userLineName === NSEWL ? workCenterList.filter(item => item.value == "FSEML") : workCenterList)
      } else {
        this.workCenters = [];
      }
    },
    async loadPlannerGroups() {
      const result = await GetMOMSPlannerGroups({ 
        lineId: this.userLineId,
        branch: get(this.formData.branch, 'value', ''),
      })
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.plannerGroups = get(result, "payload.list");
      } else {
        this.plannerGroups = [];
      }
    },
    async loadFunctionalLocations() {
      this.isLoadingFunctionalLocation = true;
      const result = await GetMOMSFunctionalLocations({ 
        lineId: this.userLineId,
        branch: get(this.formData.branch, 'value', ''),
      })
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.functionalLocationOptions = get(result, "payload.list");
      } else {
        this.functionalLocationOptions = [];
      }
      this.isLoadingFunctionalLocation = false;
    },
    async onSubmit() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      this.isSubmitting = true;
      const result = await createStationFault({
        lineId: this.userLineId,
        stationId: this.stationId,
        description: this.formData.description,
        isMaintainanceStaffInformed: this.formData.isMaintainanceStaffInformed,
        attachments: uploadedIds,
        functionalLocation: get(this.formData.functionalLocation, "value"),
        plannerGroup: this.formData.plannerGroup,
        workCenter: this.formData.workCenter,
        planningPlant: get(this.formData.functionalLocation, "planningPlant"),
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
